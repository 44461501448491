var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.loading)?[_c('b-container',{staticClass:"mt-9"},[_c('b-row',[_c('b-col',{staticClass:"mb-8",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-button',{attrs:{"variant":"transparent"},on:{"click":e => _vm.$router.go(-1)}},[_c('inline-svg',{attrs:{"src":require('@/assets/icons/chevron-left.svg'),"height":"20","width":"15"}})],1),_c('h2',{staticClass:"font-weight-bold heading d-inline-block ml-5 mb-0"},[_vm._v(" "+_vm._s(_vm.$t("libraryContent.backToMentorLibrary"))+" ")])],1),_c('b-col',{staticClass:"text-right mt-8 mt-md-0",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.profile.type === 'mentor')?_c('b-button',{staticClass:"btn-library",staticStyle:{"height":"auto"},attrs:{"variant":"primary"},on:{"click":_vm.openContentStatisticsModal}},[_c('inline-svg',{staticClass:"d-inline-block",attrs:{"src":require('@/assets/icons/library-stats.svg'),"height":14,"width":14}}),_c('span',[_vm._v(_vm._s(_vm.$t("libraryContent.viewContentStateBtn")))])],1):_vm._e(),(_vm.profile.type === 'mentor')?_c('b-button',{staticClass:"btn-library main",staticStyle:{"height":"auto"},attrs:{"variant":"primary"},on:{"click":_vm.openContentEditModal}},[_c('inline-svg',{staticClass:"d-inline-block",attrs:{"src":require('@/assets/icons/edit-outlined-white.svg'),"height":14,"width":14}}),_c('span',[_vm._v(_vm._s(_vm.$t("libraryContent.viewContentViewContentModalEditBtn")))])],1):_vm._e(),_c('ShareDropdown',{staticClass:"h-100 library-share-button",attrs:{"text":"Share","type":"library","is-outline":"","url":_vm.getLibraryItemLink,"data":{
                    title: 'Check out my library item from mentorice!',
                    description: 'Check out my library item from mentorice!'
                  }}}),(_vm.profile.type === 'mentee')?_c('b-button',{staticClass:"btn-library",attrs:{"variant":"primary"},on:{"click":_vm.openViewModal}},[_c('inline-svg',{staticClass:"d-inline-block mr-1",attrs:{"src":require(`@/assets/icons/library-${
                        !_vm.content.is_purchased
                          ? 'cart-white'
                          : 'purchased-tick'
                      }.svg`),"height":_vm.content.is_purchased ? 14 : 20,"width":_vm.content.is_purchased ? 14 : 20}}),_c('span',[_vm._v(_vm._s(_vm.content.is_purchased ? _vm.$t("libraryContent.purchasedLabel") : _vm.$t("libraryContent.purchaseLabel")))])],1):_vm._e(),(_vm.profile.type === 'mentee' && _vm.content.is_purchased)?_c('b-button',{staticClass:"btn-library",attrs:{"variant":"primary"},on:{"click":_vm.downloadItem}},[_c('inline-svg',{staticClass:"d-inline-block mr-1",attrs:{"src":require(`@/assets/icons/library-content-download.svg`),"height":18,"width":18}}),_c('span',[_vm._v(_vm._s(_vm.$t("libraryContent.viewContentGetContentBtn")))])],1):_vm._e()],1)])],1)],1),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-img',{staticClass:"object-cover w-100 rounded",attrs:{"src":_vm.content.cover_image,"alt":"","height":"250px"}})],1),_c('b-col',{attrs:{"cols":"12","md":"9"}},[_c('h3',{staticClass:"heading font-weight-bold mb-0 pb-5",staticStyle:{"border-bottom":"0.4px solid #DDE7FE"}},[_vm._v(" "+_vm._s(_vm.content.title)+" ")]),_c('div',{staticClass:"d-flex justify-content-between align-items-center py-5",staticStyle:{"border-bottom":"0.4px solid #DDE7FE"}},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"column-gap":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("libraryContent.distributor"))+": "),_c('div',{staticClass:"d-flex align-items-center ml-3",staticStyle:{"column-gap":"10px"}},[_c('b-img',{staticClass:"rounded-circle",staticStyle:{"border":"0.5px solid #3E85D0","padding":"3px"},attrs:{"src":_vm.content.user.profile_link,"alt":"","width":"40","height":"40"}}),_c('router-link',{attrs:{"to":{
                    name: 'MentorProfilePage',
                    params: { id: _vm.content.user.uuid }
                  }}},[_c('p',{staticStyle:{"color":"#3E85D0","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.distributorName)+" ")])])],1)]),_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"column-gap":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("libraryContent.price"))+": "),_c('p',{staticStyle:{"color":"#3E85D0","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.formattedPrice)+" "+_vm._s(_vm.platformCurrency)+" ")])])]),_c('div',{staticClass:"d-flex justify-content-between align-items-center py-5",staticStyle:{"border-bottom":"0.4px solid #DDE7FE"}},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"column-gap":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("libraryContent.dateAdded"))+": "),_c('p',{staticStyle:{"color":"#3E85D0","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.formattedDate)+" ")])]),_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"column-gap":"10px"}},[_vm._v(" "+_vm._s(_vm.$t("libraryContent.totalPurchases"))+": "),_c('p',{staticStyle:{"color":"#3E85D0","font-weight":"500"}},[_vm._v(" "+_vm._s(_vm.content.bookings_count)+" ")])])]),_c('div',{staticClass:"py-5"},[_c('p',[_vm._v(_vm._s(_vm.content.description))])])])],1),_c('ViewContentModal',{key:"view-content-modal",attrs:{"content":_vm.content}}),(_vm.profile.type === 'mentor')?_c('EditContentModal',{key:"edit-content-modal",attrs:{"library-content":_vm.content}}):_vm._e(),(_vm.profile.type === 'mentor')?_c('ContentStatisticsModal',{key:"stat-content-modal",attrs:{"content":_vm.content}}):_vm._e()],1)]:[_c('LibraryItemPageSkeleton')]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
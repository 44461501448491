
import Vue from "vue";
import { mapGetters } from "vuex";
import moment from "moment";
import ShareDropdown from "@/components/ShareDropdown.vue";
import ViewContentModal from "@/components/Library/ViewContentModal.vue";
import EditContentModal from "@/components/Library/EditContentModal.vue";
import LibraryItemPageSkeleton from "@/components/Library/LibraryItemPageSkeleton.vue";
import ContentStatisticsModal from "@/components/Library/ContentStatisticsModal.vue";

export default Vue.extend({
  name: "LibraryItemPage",
  components: {
    ShareDropdown,
    ViewContentModal,
    EditContentModal,
    LibraryItemPageSkeleton,
    ContentStatisticsModal
  },
  data() {
    return {
      content: {} as any,
      loading: false as boolean
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency",
      profile: "profile/getProfile"
    }),
    distributorName(): string {
      return this.content.user.first_name + " " + this.content.user.last_name;
    },
    formattedPrice(): string {
      return this.content.price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formattedDate(): string {
      return moment(this.content.created_at).format("Do MMMM, YYYY");
    },
    getLibraryItemLink(): string {
      const { href } = this.$router.resolve({
        name: "LibraryItemPage",
        params: { id: this.content.id }
      });
      const url = new URL(href, window.location.origin).href;
      return url;
    }
  },
  mounted() {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
    this.getContent();
  },
  methods: {
    openViewModal(): void {
      (this.$bvModal as any).show("view-content-modal");
    },
    openContentEditModal(): void {
      (this.$bvModal as any).show("edit-content-modal");
    },
    openContentStatisticsModal() {
      (this.$bvModal as any).show("content-statistics-modal");
    },
    async getContent() {
      this.loading = true;

      try {
        const { content } = await (this.$store as any).dispatch(
          "library_content/GET_CONTENT",
          (this.$route as any).params.id
        );
        this.content = content;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    downloadItem() {
      if (this.content.content_type === "file") {
        const link = document.createElement("a");
        link.href = this.content.source;
        link.download = this.content.title;
        link.click();
      } else {
        window.open(this.content.source);
      }
    }
  }
});
